import React from 'react';

const Built = () => {
  return (
    <div className="tile is-ancestor">
      <div>
        <img src="/img/work/bottles_up_cork.jpeg" alt="Cork map" />
      </div>
      <div className="flex is-parent py-4">
        <div className="tile resp-container">
          <iframe
            className="resp-iframe"
            src="https://player.vimeo.com/video/482070053"
            title="BottlesUp! Cork Map"
            frameBorder="0"
            allowFullScreen={true}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
          />
        </div>
      </div>

      <div className="flex items-center">
        <article className="tile is-child" style={{ flexGrow: '2' }}>
          <img src="/img/work/bottles-up-sign.jpeg" alt="" />
        </article>
        <article className="tile is-child px-2">
          <img src="/img/work/cheers.jpg" alt="" />
        </article>
        <article className="tile is-child" style={{ flexGrow: '2' }}>
          <img src="/img/work/bottles_up_cork_close.jpg" alt="" />
        </article>
      </div>
      <div className="flex tile">
        <div className="tile is-parent" style={{ flexGrow: '2' }}>
          <article className="py-4 tile is-child">
            <img src="/img/work/mandap_final.jpeg" alt="Mandap" />
          </article>
        </div>
        <div className="tile flex flex-col is-vertical pl-4">
          <article className="py-4 tile is-child">
            <img src="/img/work/mandap_1.jpeg" alt="Mandap" />
          </article>

          <article className="tile is-child">
            <img src="/img/work/mandap_2.jpeg" alt="Mandap" />
          </article>

          <article className="py-4 tile is-child">
            <img src="/img/work/mandap_3.jpeg" alt="Mandap" />
          </article>
        </div>
      </div>
      <div className="flex">
        <article className="tile is-child">
          <img src="/img/work/guitar_cut.jpeg" alt="" />
        </article>
        <article className="tile is-child">
          <img src="/img/work/guitar_cut2.jpeg" alt="" />
        </article>
        <article className="tile is-child">
          <img src="/img/work/guitar_cut4.jpeg" alt="" />
        </article>
        <article className="tile is-child">
          <img src="/img/work/guitar_cut3.jpeg" alt="" />
        </article>
      </div>
      <div className="flex">
        <div className="tile is-parent" style={{ flexGrow: '2' }}>
          <article className="py-4 tile is-child">
            <img src="/img/work/wedding.JPeG" alt="Wedding sign" />
          </article>
        </div>
        <div className="tile is-parent">
          <article className="py-4 pl-4 tile is-child">
            <img src="/img/work/cutting_board.JPeG" alt="Cutting Board" />
          </article>
        </div>
      </div>
      <div className="flex items-center">
        <div className="tile is-parent">
          <article className="tile is-child">
            <img src="/img/work/frame.JPeG" alt="Frame" />
          </article>
        </div>
        <div className="tile is-parent">
          <article className="px-4 tile is-child">
            <img src="/img/work/chi.jpeg" alt="Chicago flag" />
          </article>
        </div>
        <div className="tile is-parent">
          <article className="py-4 tile is-child">
            <img src="/img/work/joie_sarah.jpeg" alt="Wedding sign" />
          </article>
        </div>
      </div>
      <div className="flex items-center">
        <div className="tile py-4 is-parent">
          <article className="tile is-child">
            <img src="/img/work/wedding1.JPeG" alt="Wedding sign" />
          </article>
        </div>
        <div className="tile is-parent">
          <article className="pl-4 tile is-child">
            <img src="/img/work/bryce.JPeG" alt="Wedding sign" />
          </article>
        </div>
      </div>
      {/* <div className="flex is-parent">
    <div className="flex tile" style={{ flexGrow: 2 }}>
      <img src="/img/work/cs_sign.jpeg" alt="Camp Shutout sign" />
    </div>
    <div className="flex tile">
      <img src="/img/work/cs_sign2.jpeg" alt="Camp Shutout sign" />
    </div>
    <div className="flex tile">
      <img src="/img/work/cs_sign1.jpeg" alt="Camp Shutout sign" />
    </div>
  </div> */}
      <div className="flex is-parent">
        <article className="p-4 tile is-child">
          <img src="/img/work/mountain.jpeg" alt="Wooden mountain wall art" />
        </article>
      </div>
      <div className="flex">
        <div className="tile is-parent">
          <article className="p-4 tile is-child">
            <img
              src="/img/work/table_far.jpeg"
              alt="A overview of a table with circuit inlay"
            />
          </article>
        </div>
        <div className="tile is-parent">
          <article className="p-4 tile is-child">
            <img
              src="/img/work/table_close.jpeg"
              alt="A overview of a table with circuit inlay"
            />
          </article>
        </div>
      </div>
      <div className="flex">
        <div className="tile is-parent is-vertical" style={{ flexGrow: '2' }}>
          <article className="p-4 tile is-child">
            <img src="/img/work/easel-brain.jpeg" alt="The brain in Easel" />
          </article>
        </div>
        <div className="tile is-parent" style={{ flexShrink: '2' }}>
          <article className="p-4 tile is-child">
            <img src="/img/work/brain-carved.jpeg" alt="The Brain" />
          </article>
        </div>
      </div>
    </div>
  );
};

export default Built;
